import React, { useContext } from 'react';
import { Avatar, Typography, Container, Box, Button } from '@mui/material';
import { AuthContext } from '../context/AuthContext';

const ProfilePage = () => {
    const { currentUser } = useContext(AuthContext);

    // Function to generate avatar background color and initials
    const stringAvatar = (name) => {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    };

    // Simple hash function to generate a color based on a string
    const stringToColor = (string) => {
        let hash = 0;
        for (let i = 0; i < string.length; i++) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        const color = Math.abs(hash).toString(16).substring(0, 6);
        return `#${'0'.repeat(6 - color.length)}${color}`;
    };

    return (
        <Container maxWidth="md">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 5 }}>
                <Avatar {...stringAvatar(`${currentUser.firstName} ${currentUser.lastName}`)} sx={{ width: 100, height: 100, mb: 2 }} />
                <Typography variant="h4" gutterBottom>
                    {`${currentUser.firstName} ${currentUser.lastName}`}
                </Typography>

                <Typography variant="h6" color="textSecondary" gutterBottom>
                    {currentUser.email}
                </Typography>
                <Button variant="contained" color="primary" sx={{ mt: 3 }}>
                    Edit Profile
                </Button>
            </Box>
        </Container>
    );
};

export default ProfilePage;
