import React from 'react'
import Container from '@mui/material/Container';

import { Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ListPage = () => {

    const text = "Ponúkame strojove čistenie extrémne zarastených pozemkov.Kosenie, pílenie stromov, mulčovanie (šípky,kroviny, náletove dreviny, zanedbané sady, záhrady).Disponujeme technikou, ako sú šmykom riadené pásové nakladače a bágre /Takeuchi, Caterpillar, Bobcat/. Na dané stroje máme rotačné kosačky, ktoré dokážu pokosiť od trávy až po nálety, kríky a dreviny do priemeru 14cm.Prácu ktorú vykoná náš stroj za pár hodín nahradí partiu ľudí, ktorá by pracovala na danej lokalite niekoľko dní až týždňov.Vykopávanie koreňov.Terénne úpravy pomocou bágru a nakladaču.Práce vykonávame na celom území Slovenska a ČR.";
    return (
        <Container maxWidth="lg" align='center' >
            <Grid container spacing={5} style={{ marginTop: "25px" }} >
                Card1 <Card sx={{ maxWidth: 345 }} style={{ marginBottom: "30px" }}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="140"
                        image="https://www.bazos.sk/img/2/222/164125222.jpg?t=1713771805"
                    />
                    <CardContent style={{ padding: 0 }}>
                        <Box style={{ padding: 16, position: 'relative', top: -5, backgroundImage: 'linear-gradient(to bottom, transparent 0%, rgb(253, 107, 2) 100%)' }}>
                            <Typography gutterBottom variant="h6" component="div" >
                                "Čistenie pozemkov, kosenie, mulčovanie.
                            </Typography>
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                            {text.slice(0, 256)}...
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small">Share</Button>
                        <Button size="small">Learn More</Button>
                    </CardActions>
                </Card>
            </ Grid>
        </Container>
    )
}

export default ListPage