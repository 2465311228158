import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { FormGroup } from '@mui/material';


import apiRequest from '../lib/apiRequest';


const SignUp = () => {
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState("")

    const handleSubmit = async (event) => {
        event.preventDefault()
        const formData = new FormData(event.target);

        const firstName = formData.get("firstName")
        const lastName = formData.get("lastName")
        const email = formData.get("email")
        const phoneNumber = formData.get("phoneNumber")
        const password = formData.get("password")
        try {
            const res = await apiRequest.post("/auth/register", {
                firstName, lastName, email, phoneNumber, password
            })
            setMsg(res.message)
        }
        catch (err) {
            console.log(err)
            setError(err.response.data.message)
        } finally {
            setIsLoading(false)
        }

    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                component="form" noValidate onSubmit={handleSubmit}
            >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h3">
                    Registrácia
                </Typography>
                <Typography component="h5" variant="h5">
                    Chcem ponúkať:
                </Typography>

                <FormGroup>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value="zivnostnik"
                                        color="primary"
                                    />
                                }
                                label="Živnostník"
                            />
                        </Grid>
                    </Grid>
                </FormGroup>

                <Box sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="Meno"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Priezvisko"
                                name="lastName"
                                autoComplete="family-name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="phoneNumber"
                                required
                                fullWidth
                                id="phoneNumber"
                                label="Tel. číslo"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Heslo"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="Súhlasím so zasielaním informácií ohľadom zliav, noviniek a kupónov."
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" required />}
                                label="Súhlasím so všeobecnými podmienkami."
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" required />}
                                label="Súhlasím so spracovaním osobných údajov. Prehlasujem, že ak mám menej ako 16 rokov, tak som požiadal svojho zákonného zástupcu (rodiča) o súhlas so spracovaním mojich osobných údajov."
                            />
                        </Grid>
                    </Grid>
                    <Button
                        disabled={isLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Registrovať
                    </Button>
                    {error && <span>{error}</span>}
                    {msg && <span>{msg}</span>}
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/login" variant="body2">
                                Máš už účet? <span> Prihlás sa</span>
                            </Link>
                        </Grid>
                    </Grid>
                </Box>

            </Box>

        </Container >

    );
}

export default SignUp