import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import SharedLayout from './routers/SharedLayout/SharedLayout';
import Home from './routers/HomePage/Home';
import AboutService from './routers/AboutService';
import CraftsMan from './routers/CraftsMan';
import About from './routers/About';
import Login from './routers/Login';
import Register from './routers/Register';
import ProfilePage from './routers/ProfilePage';
import ListPage from './routers/ListPage/ListPage';
import EmailVerify from './components/emailVerify/EmailVerify';
import SignUp from './routers/SignUp';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SharedLayout />}>
          <Route index path="/" element={<Home />} />
          <Route path="/ako-to-funguje" element={<AboutService />} />
          <Route path="/nasi-zivnostnici" element={<CraftsMan />} />
          <Route path="/o-nas" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profilePage" element={<ProfilePage />} />
          <Route path="/listPage" element={<ListPage />} />
          <Route path="/users/:id/verify/token" element={<EmailVerify />} />
          <Route path="/signup" element={<SignUp />} />
        </Route>
      </Routes>
    </BrowserRouter>

  );
}

export default App;
