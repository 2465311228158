import React, { useEffect, useState } from 'react'
import logo from "../../assets/images/logoHlavne.png"
import { Button } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import apiRequest from '../../lib/apiRequest'


const EmailVerify = () => {
    const [validUrl, setValidUrl] = useState(false)
    const param = useParams();

    useEffect(() => {
        const verifyEmailUrl = async () => {
            try {
                const url = `http://localhost:3000/api/v1/users/${param.id}/verify${param.token}`
                const { data } = apiRequest.get(url);
                console.log(data)
                setValidUrl(true)
            } catch (error) {

            }
        }
        verifyEmailUrl()
    }, [param])
    return (
        <>
            {
                validUrl ? (
                    <div>
                        <img src={logo} alt="/" />
                        <h1> Emailová adresa úspešne overená</h1>
                        <Link to="/login">
                            <Button> Prihlasiť sa</Button>
                        </Link>
                    </div>
                ) : (
                    <h1> 404 Stránka neexistuje </h1>
                )
            }
        </>
    )
}

export default EmailVerify