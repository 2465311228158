import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#fd6b02',
            contrastText: '#000000',
        },
        secondary: {
            main: '#ffa700',
        },
        error: {
            main: '#fd0000',
            light: '#fd3333',
            dark: '#b10000',
            contrastText: '#ffffff',
        },
        background: {
            default: '#ffffff',
            paper: '#ffffff',
        },
        text: {
            primary: 'rgba(76,75,75,0.87)',
            secondary: 'rgba(53,53,53,0.6)',
            disabled: 'rgba(0,0,0,0)',
            hint: '#22194d',
            logo: '#fd6b02'
        },
    },
    // palette: {
    //     mode: 'light',
    //     primary: {
    //         main: '#fd6b02',
    //     },
    //     secondary: {
    //         main: '#ffa700',
    //     },
    //     error: {
    //         main: '#fd0000',
    //         light: '#fd3333',
    //         dark: '#b10000',
    //         contrastText: '#ffffff',
    //     },
    //     text: {
    //         primary: '#000000',
    //     },
    //     background: {
    //         default: '#ffffff',
    //         paper: '#ffffff',
    //     },
    // },

});
