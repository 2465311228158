import React, { useContext, useState } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { NavLink, useNavigate } from 'react-router-dom';
import apiRequest from '../lib/apiRequest';
import { AuthContext } from '../context/AuthContext';



const Login = () => {
    const [error, setError] = useState("")
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)

    const { updateUser } = useContext(AuthContext)

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const formData = new FormData(event.target);

        const email = formData.get("email")
        const password = formData.get("password")
        try {
            const res = await apiRequest.post("/auth/login", {
                email,
                password,
            })
            updateUser(res.data)
            navigate("/")
        }
        catch (err) {
            console.log(err)
            setError(err.response.data.message)
        } finally {
            setIsLoading(false)
        }

    };

    return (

        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h3">
                    Prihlásenie
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Heslo"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Zapamätáť si ma"
                    />
                    <Button disabled={isLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Prihlásiť sa
                    </Button>
                    {error && <span>{error}</span>}
                    <Grid container>
                        <Grid item xs>
                            <NavLink to="/register" variant="body2">
                                Zabudol som heslo?
                            </NavLink>
                        </Grid>
                        <Grid item>
                            <NavLink to="/signup" variant="body2">
                                {"Nemáš ešte účet? Zaregistruj sa"}
                            </NavLink>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

        </Container>

    );
}

export default Login