import axios from "axios";

import config from "../config"

const apiRequest = axios.create(
    {
        baseURL: config.baseBackendApi,
        withCredentials: true,
    }
)
export default apiRequest;