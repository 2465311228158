import React from 'react'
import ListPage from '../ListPage/ListPage'

const Home = () => {


    return (
        <div><ListPage /></div>
    )
}

export default Home